<script>
import { mapState } from 'vuex'

export default {
  name: 'Spinner',
  computed: {
    ...mapState(['fetching'])
  }
}
</script>

<template>
  <div
    v-if="fetching"
    class="spinner-container"
  >
    <div class="lds-ellipsis">
      <div /><div /><div /><div />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/spinner.css"></style>
